<template>
  <div>
    <div class="text-center mb-2">
<!--      <h2 class="mb-5">-->
<!--        <strong>Welcome to {{ settings.logo }}</strong>-->
<!--      </h2>-->
      <img class="login_page_image" src="/resources/images/Daraz_Lockup_Vertical_Orange_RGB.png" alt="Daraz">
    </div>
    <div class="card" :class="$style.container" style="margin-bottom: 2px !important;">
      <div class="text-dark text-center mb-3">
        <strong><p style="font-size: 30px; margin-bottom: 0px !important;">Welcome to D-OMS</p></strong>
        <strong><p style="font-size: 26px">Daraz Office Management System</p></strong>
      </div>
      <div class="text-dark font-size-23 mb-3">
        <strong>Sign in to your account</strong>
      </div>
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input size="large" placeholder="User Name" v-decorator="['email', { rules: [{ required: true, message: 'Please input your username!' }]}]"/>
        </a-form-item>
        <a-form-item>
          <a-input size="large" placeholder="Password" type="password" v-decorator="['password', { rules: [{ required: true, message: 'Please input your Password!' }]}]"/>
        </a-form-item>
        <a-button type="primary" htmlType="submit" size="large" class="text-center w-100" :loading="loading">
          <strong>Sign in</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/forgot-password-request" class="kit__utils__link font-size-16">Forgot Password?</router-link>
    </div>
    <div class="text-center mb-2">
      <router-link to="/room/b" class="kit__utils__link font-size-18">For <b>CONFERENCE ROOM</b> click here</router-link>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CuiLogin',
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    changeAuthProvider(value) {
      this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('user/LOGIN', { payload: values })
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
.card {
  margin-bottom: 0px !important;
}
</style>
